import React from "react";
import Logo from "../media/WebDesign.png";
import "../css/main.min.css";


//render document elements
function Home() {
  return (
    <div className="bg-primary" style={{height: '92vh'}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-fluid text-center pt-4">
              <img src={Logo} alt="Web Design Logo" className="img-fluid"/>
          </div>
          <div className="col-fluid col-sm-8 col-lg-6 col-xl-4 col-xxl-4 text-center">
              <h1 className="fs-4 text-light p-0 m-0">A single programmer making websites for people.</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;