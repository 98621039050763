import React from "react";

// Portfolio Body Page

function Portfolio() {
    return (
        <div>
          <div className="home bg-primary">
            <div className="container-fluid">
              <div className="row align-items-center justify-content-center" style={{height: '92vh'}}>
                  <div className="col-fluid">
                      <h1 className="text-light mt-9 text-center">Portfolio</h1>
                  </div>
                  <div className="col-fluid col-sm-8 col-lg-6 text-center mb-10">
                      <p className="fs-3 text-light"> The technology used below is not Wordpress/CMS of any kind making 
                          it a very secure solution for your business. Every website is built from the 
                          ground up and then put in the cloud by me. Below are some website examples I 
                          have completed while in school just click on them and be redirected to the page, enjoy.</p>
                  </div>
                </div>
            </div>
            <div className="container-fluid pt-15 pb-15 bg-secondary" style={{height: '100vh'}}>
                <div className="row justify-content-center text-center">
                    <div className="col-12 m-2">
                      <p>Class content I created and got graded on</p>
                      <a href="https://cis166aa.jacobfox.me" target="_blank" rel="noreferrer">Javascript Specific course</a>
                    </div>
                    <div className="col-12 m-2">
                      <a href="https://cis166aa.jacobfox.me" target="_blank" rel="noreferrer">Javascript Specific course</a>
                    </div>
                </div>
            </div>
          </div>
        </div>
      );
    }

export default Portfolio;