import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react"
import "../App.css";

export default function Navigation() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)

  return (
    <nav className="navigation">
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul className="ms-auto mb-2 mb-lg-0 text-end">
               <li className="nav-margin">
                 <NavLink className="nav-link text-end fs-5 text-light Navlink" to="/">
                   Home
                   <span className="sr-only"></span>
                 </NavLink>
               </li>
               <li className="nav-margin">
                 <NavLink className="nav-link text-end fs-5 text-light" to="/portfolio">
                   Portfolio
                 </NavLink>
               </li>
               <li className="nav-margin">
                 <NavLink className="nav-link text-end fs-5 text-light" to="/about">
                   About Me
                 </NavLink>
               </li>
            </ul>
      </div>
    </nav>
  );
}