import React from "react";
import Me from "../media/Me.jpg";



//About Me Page

function About() {
  return (
    <div className="bg-secondary" style={{height: '92vh'}}>
      <div className="container">
              <div className="row">
                  <div className="col-12 mt-3 text-center">
                      <h3 className="fs-1">About</h3>
                  </div>
              </div>
              <div className="row justify-content-center mt-5 bg-secondary">
                  <div className="col-8 col-xs-3 col-md-3 col-lg-2 col-xl-3 col-xxl-3 pl-3">
                      <img src={Me} alt="Jacob Fox" className="img-fluid mt-5"/>
                  </div>
                  <div className="col-12 col-md-9 col-xl-9 col-xxl-9">
                      <p className="fs-3 p-5">Hello I'm 25 and am in school and getting 
                          my associates for web development and design from the local community college. I enjoy all 
                          tech both software and hardware, I first started out with hardware though. 
                          That being said I've done programming and software for about 1-2 years and am 
                          comfortable with it. Beyond programming I enjoy wood-working and fixing things 
                          to take a break with programming since it can be strenuous.</p>
                  </div>
              </div>
          </div>
        </div>
  );
}

export default About;