import React from "react";


// Footer info page

function Footer() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-fluid">
          <div className="footer">
            <footer className="bg-transparent fixed-bottom">
              <div className="container">
                <p className="text-center text-dark">
                  Made with Care {new Date().getFullYear()}
                </p>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Footer;